













































import FinalizeTs from './FinalizeTs';
export default class Finalize extends FinalizeTs {}
